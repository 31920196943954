<template>
    <div class="app-container">
      <el-row>
        <el-col :span="12">
          <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
        </el-col>
        <el-col :span="12">
          <el-button @click="sendEmail">send message</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-table  v-if="tableData.length > 0" :data="tableData" border highlight-current-row style="width: 100%;margin-top:20px;"  @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column v-for="item of tableHeader" :key="item" :prop="item" :label="item" />
        </el-table>
      </el-row>
    </div>
  </template>
  
  <script>
  import axios from '@axios'
  import UploadExcelComponent from '@/views/excel/ExcelUploadComponent.vue'
  
  export default {
    name: 'UploadExcel',
    components: { UploadExcelComponent },
    data() {
      return {
        tableData: [],
        tableHeader: [],
        multipleSelection: []
      }
    },
    methods: {
      beforeUpload(file) {
        const isLt1M = file.size / 1024 / 1024 < 10
  
        if (isLt1M) {
          return true
        }
  
        this.$message({
          message: 'Please do not upload files larger than 1m in size.',
          type: 'warning'
        })
        return false
      },
      handleSuccess({ results, header }) {
        this.tableData = []
        this.tableData = results
        this.tableHeader = header
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      sendEmail() {
        if (this.multipleSelection.length == 0) {
          alert('uuchlaarai muruu songono uu')
          return
        }
        console.log(this.multipleSelection)
        let textBegin = 'Numuriin ger bulees ni hariltsagch tanii zeel avah erhig sergeeh sanal tawij baina. Та daraah holboosoor https://numur.mn/check.html?q='
        let textEnd = ' huseltee gargana uu.7511-1133'
        let key = 'ee95112bd03cfa6f1fac29c9fde3f0fb'
        let from = '72271131'
        for (let index in this.multipleSelection) {
          if (this.multipleSelection[index].rd != null && this.multipleSelection[index].rd != '') {
            let text = textBegin + this.multipleSelection[index].rd.toUpperCase(); + textEnd //УХ93032023
            let to = this.multipleSelection[index].phone //88006457
            console.log('https://api.messagepro.mn/send?key=' + key + '&from=' + from + '&to=' + to + '&text=' + text)
            var requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };

            fetch('https://api.messagepro.mn/send?key=' + key + '&from=' + from + '&to=' + to + '&text=' + text, requestOptions)
              .then(response => response.text())
              .then(result => {
                console.log(result)
                this.sleep(500)
              })
              .catch(error => { 
                console.log('error', error)
              });
          }
        }

      },
      sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
      }
    }
  }
  </script>
  