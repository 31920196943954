<template>
    <el-row>
      <el-row>
        <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick">
        <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
          Drop excel file here or
          <el-button :loading="loading" style="margin-left:16px;" size="mini" type="primary" @click="handleUpload">
            Browse
          </el-button>
        </div>
      </el-row>
      <el-row>
        <el-button v-for="sheetName in sheetNames" :key="sheetName" @click="showData(sheetName)">{{ sheetName }}</el-button>
      </el-row>
    </el-row>
  </template>
  
  <script>
  var XLSX = require("xlsx");
  
  export default {
    props: {
      beforeUpload: Function, // eslint-disable-line
      onSuccess: Function// eslint-disable-line
    },
    data() {
      return {
        loading: false,
        excelData: {
          header: null,
          results: null
        },
        rawFile: null,
        sheetNames: [],
        firstSheetName: null,
        workbook: null,
        worksheet: null,
        data: null
      }
    },
    methods: {
      generateData({ header, results }) {
        console.log(results)
        this.excelData.header = header
        this.excelData.results = results
        this.onSuccess && this.onSuccess(this.excelData)
      },
      handleDrop(e) {
        e.stopPropagation()
        e.preventDefault()
        if (this.loading) return
        const files = e.dataTransfer.files
        if (files.length !== 1) {
          this.$message.error('Only support uploading one file!')
          return
        }
        const rawFile = files[0] // only use files[0]
  
        if (!this.isExcel(rawFile)) {
          this.$message.error('Only supports upload .xlsx, .xls, .csv suffix files')
          return false
        }
        this.upload(rawFile)
        e.stopPropagation()
        e.preventDefault()
      },
      handleDragover(e) {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
      },
      handleUpload() {
        this.$refs['excel-upload-input'].click()
      },
      handleClick(e) {
        const files = e.target.files
        const rawFile = files[0] // only use files[0]
        if (!rawFile) return
        this.upload(rawFile)
      },
      upload(rawFile) {
        this.rawFile = null
        this.$refs['excel-upload-input'].value = null // fix can't select the same excel
        this.rawFile = rawFile
        if (!this.beforeUpload) {
          this.readerData(rawFile)
          return
        }
        const before = this.beforeUpload(rawFile)
        if (before) {
          this.readerData(rawFile)
        }
      },
      readerData(rawFile) {
        this.loading = true
        this.workbook = null
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = e => {
            this.data = e.target.result
            this.workbook = XLSX.read(this.data, { type: 'array' })
            this.sheetNames = this.workbook.SheetNames;
            this.showData(this.firstSheetName)
            this.loading = false
            resolve()
          }
          reader.readAsArrayBuffer(rawFile)
        })
      },
      showData(sheetName) {
        if (sheetName)
          this.firstSheetName = sheetName
        else
          this.firstSheetName = this.workbook.SheetNames[0]
        this.worksheet = this.workbook.Sheets[this.firstSheetName]
        const header = this.getHeaderRow(this.worksheet)
        const results = XLSX.utils.sheet_to_json(this.worksheet)
        this.generateData({ header, results })
      },
      getHeaderRow(sheet) {
        const headers = []
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C
        const R = range.s.r
        /* start in the first row */
        for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
          /* find the cell in the first row */
          let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
          if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
          headers.push(hdr)
        }
        return headers
      },
      isExcel(file) {
        return /\.(xlsx|xls|csv)$/.test(file.name)
      }
    }
  }
  </script>
  
  <style scoped>
  .excel-upload-input{
    display: none;
    z-index: -9999;
  }
  .drop{
    border: 2px dashed #bbb;
    width: 600px;
    height: 160px;
    line-height: 160px;
    margin: 0 auto;
    font-size: 24px;
    border-radius: 5px;
    text-align: center;
    color: #bbb;
    position: relative;
  }

  .el-row {
    margin: 5px 0;
  }
  </style>
  